<script>
import appConfig from "@/app.config";
import OtpInput from "otp-input-vue2";
import { VueTelInput } from "vue-tel-input";
import { required } from "vuelidate/lib/validators";
import $ from "jquery";

/**
 * Register component
 */
export default {
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    OtpInput,
    VueTelInput,
  },
  data() {
    return {
      processing: false,
      phone: null,
      phoneError: true,
      otpCode: null,
      verification: {
        waiting: false,
        timer: 60,
        clearInterval: null,
      },
    };
  },
  validations: {
    phone: { required },
  },
  methods: {
    showModal() {
      this.$bvModal.show("bv-modal-otp");
    },
    hideModal() {
      this.$bvModal.hide("bv-modal-otp");
    },
    onCompleteHandler(code) {
      this.otpCode = code;
    },
    validateOTP() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const config = {
        headers: { Authorization: `Bearer ` + this.$route.query.token },
      };

      this.processing = true;
      this.$axios
        .post("/user/validate-otp", { token: this.otpCode }, config)
        .then((response) => {
          this.triggerSwal(response.data.message, "success");
          window.location.href = "/employee-profile";
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          this.processing = false;
        });
    },

    waitForSomeSeconds() {
      this.verification.waiting = true;
      setTimeout(() => {
        this.verification.waiting = false;
        this.verification.timer = 60;
        clearInterval(this.verification.clearInterval);
      }, 60000);
      this.startTimer();
    },

    startTimer() {
      this.verification.clearInterval = setInterval(() => {
        this.verification.timer -= 1;
      }, 1000);
    },

    sendOtp() {
      this.waitForSomeSeconds();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.phoneError == false) {
        return;
      }
      const config = {
        headers: { Authorization: `Bearer ` + this.$route.query.token },
      };
      this.$axios
        .post("/user/send-otp", { phone: this.phone }, config)
        .then((response) => {
          this.triggerSwal(response.data.message, "success");
          this.hideModal();
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        });
    },

    checkPhone(phone) {
      if (this.phone) this.phoneError = phone.valid;
    },
  },
  mounted() {
    this.phone = this.$route.query.phone;
  },
  beforeDestroy() {
    clearInterval(this.verification.clearInterval);
  },
};
</script>

<template>
  <div>
    <div class="register-page register-template">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-4 col-xl-4">
            <div class="register-img">
              <div class="text-wrap">
                <img
                  src="@/assets/images/register-image.png"
                  class="h-100 object-cover"
                  alt=""
                />
                <p>Make Your Schedule Your Choice</p>
                <h1 class="head-01">Shift fish</h1>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-8 col-xl-8">
            <div class="m-lg-4 m-2 p-lg-4 p-2">
              <div class=""></div>
              <div class="mt-4">
                <div class="rounded box-shadow px-3 py-3">
                  <div class="">
                    <div class="">
                      <h2 class="text-dark f-30 pt-1">Confirm Account</h2>
                      <p class="mb-2">
                        Confirm your account by entering the 5 digit code sent
                        to phone number ({{ phone }})
                        <a class="primaryColor pointer" @click="showModal">
                          <i class="fa fa-pen"></i> Change
                        </a>
                      </p>
                      <otp-input
                        class="mt-3"
                        @on-complete="onCompleteHandler"
                        placeholder=" "
                        :should-auto-focus="true"
                        :is-input-num="true"
                        :num-inputs="5"
                        type="number"
                        separateInputClass="separate-input-class"
                      >
                      </otp-input>
                      <SubmitButton
                        type="submit"
                        class="mt-4 secondaryColorBg"
                        @clicked="validateOTP"
                        text="Confirm Account"
                        :processing="processing"
                      ></SubmitButton>
                      <p class="text-muted mt-2">
                        Didn't receive OTP?
                        <span v-if="verification.waiting" class="primaryColor"
                          > 00 : {{ verification.timer }}</span
                        >
                        <button
                          v-if="!verification.waiting"
                          @click="sendOtp"
                          class="btn btn-link p-0 primaryColor"
                          id="countdown"
                        >
                          Resend
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="bv-modal-otp"
      hide-footer
      header-class="custom-modal-header"
      class="border-0"
      body-class="pb-5"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <template #modal-header class="p-3 p-4 text-white">
        Change Number
      </template>
      <div class="d-block">
        <form class="form px-3">
          <p class="mt-2 primaryColor">
            If you do not receive the OTP within a few minutes, Change the
            number to request for another OTP.
          </p>
          <div>
            <vue-tel-input
              v-model="phone"
              :autoFormat="true"
              :dropdownOptions="{ showFlags: true, showSearchBox: true }"
              :mode="'international'"
              @validate="checkPhone($event)"
              :validCharactersOnly="true"
              :class="{ 'is-invalid': $v.phone.$invalid }"
            ></vue-tel-input>

            <div v-if="!$v.phone.required" class="invalid-feedback">
              Phone Number is required.
            </div>
            <div v-if="!phoneError" class="text-danger mt-1">
              Invalid Phone Number.
            </div>
          </div>
          <div>
            <SubmitButton
              class="mt-4 secondaryColorBg me-2"
              @clicked="sendOtp"
              text="Resend OTP"
              :processing="processing"
            ></SubmitButton>
            <SubmitButton
              class="mt-4 btn btn-secondary"
              @clicked="hideModal"
              text="Cancel"
            ></SubmitButton>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.vue-otp-input >>> .separate-input-class {
  text-align: center;
  font-weight: bold;
  border: 1px solid grey;
  font-size: 20px;
  color: var(--primary-color) !important;
  width: 48px;
  height: 48px;
}

input::placeholder {
  font-size: 15px !important;
  text-align: center !important;
  /* font-weight: 600  !important; */
}

.modal-content {
  border: none;
}
</style>
